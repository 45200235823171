import * as orderApi from '@/api/order'
import QRCode from 'qrcodejs2'

export default {
  data () {
    return {
      qrcode: {}
    }
  },
  methods: {
    // 获取订单详情
    async getOrderDetail (data) {
      try {
        const options = {
          orderNum: data.orderNo,
          channel: data.channel
        }
        return await orderApi.getDetail(options)
      } catch (e) {
        console.log(e)
      }
      return null
    },
    // 生成微信和支付宝的支付二维码
    async genQRcodeWithWxAndZfb (data) {
      const options = {
        orderNo: data.orderNo,
        amount: data.amount,
        subject: data.subject,
        channel: data.channel,
        paytype: data.paytype
      }
      const res = await orderApi.payment(options)
      if (res.success) {
        this.genQRcode(res.data.payinfo)
      }
    },
    // 生成pos二维码
    // 前端不自定义支付的金额（订单查询方式）：
    // 比如订单号为：1234567890，分配的前缀代码为：P01，销售助手WEB的渠道码为SAW，
    // 则用于生成二维码的字符为：P01_SAW_1234567890
    async genQRcodeWithPos (data) {
      const content = ['P01', data.channel, data.orderNo]
      this.genQRcode(content.join('_'))
    },
    // 生成二维码
    async genQRcode (content) {
      await this.clearQRcode()
      this.qrcode = new QRCode('qrcode', {
        text: content,
        width: 166,
        height: 166,
        colorDark: '#000000',
        colorLight: '#ffffff',
        correctLevel: QRCode.CorrectLevel.H
      })
    },
    // 清除二维码
    clearQRcode () {
      return new Promise((resolve) => {
        if (typeof this.qrcode.clear === 'function') {
          const qrcode = document.getElementById('qrcode')
          if (qrcode) qrcode.innerHTML = ''
          this.qrcode.clear()
        }
        resolve()
      })
    }
  }
}
