<template>
  <section>
    <App
      title="Payment Method"
      left-arrow
      v-if="nonPayment"
    >
      <CellGroup>
        <Cell
          title="Order No."
          :value="payInfo.orderNo"
        />
        <Cell
          title="RM"
          :value="payInfo.amount"
          value-class="text-red"
        />
      </CellGroup>
      <div class="payment">
        <Label
          :labelData="lables"
          @onChange="labelChange"
          class="type"
        />
        <div
          id="qrcode"
          class="van-qrcode qrcode"
        ></div>
        <div class="remark">{{remark}}</div>
        <div
          @click="refreshPaymentResult"
          class="btns"
        ><Button>Refresh Payment Status</Button></div>
      </div>
    </App>
    <SuccessPage
      v-else
      title="Payment Method"
      describe="Payment Successful"
      btnText="Order Details"
      @clickInfo="clickInfo"
    ></SuccessPage>
  </section>
</template>

<script>
import Label from '_c/label/index'
import SuccessPage from './success/index'
import { CellGroup, Cell, Toast, Button, Dialog } from 'vant'
import PaymentModelMixin from './paymentModel'
import './index.less'

Toast.allowMultiple()

export default {
  name: 'checkstand',
  mixins: [
    PaymentModelMixin
  ],
  components: {
    CellGroup,
    Cell,
    Label,
    Button,
    SuccessPage
  },
  data () {
    return {
      loading: false,
      lables: {
        list: [
          { title: "Touch 'n Go", label: 'wx', paytype: 'W01', remark: '' },
          { title: 'Maybank QRPay', label: 'zfb', paytype: 'A01', remark: '' },
          { title: 'POS', label: 'pos', remark: '' }
        ]
      },
      qrCode: '',
      remark: '',
      nonPayment: true,
      orderInfo: {},
      payInfo: {},
      toast: null
    }
  },
  watch: {
    loading (value) {
      if (value) {
        this.toast = Toast.loading({
          duration: 0,
          message: 'Loading...',
          loadingType: 'spinner',
          forbidClick: true
        })
      } else {
        this.toast && this.toast.clear()
      }
    }
  },
  methods: {
    // 切换支付方式
    labelChange (on) {
      this.loading = true
      this.clearQRcode()
      this.remark = on.remark
      this.selectPaymentMode(on.label, on.paytype).finally(() => {
        this.loading = false
      })
    },
    // 选择支付方式
    selectPaymentMode (key, payType) {
      if (key === 'wx' || key === 'zfb') {
        return this.genQRcodeWithWxAndZfb({ ...this.payInfo, paytype: payType })
      } else if (key === 'pos') {
        return this.genQRcodeWithPos(this.payInfo)
      }
      return Promise.resolve()
    },
    // 刷新支付结果
    refreshPaymentResult () {
      this.loading = true
      this.getPaymentResult()
        .then(success => {
          if (success) {
            this.nonPayment = false
          } else {
            Toast('支付未完成，请更换其他支付方式或重新尝试')
          }
        })
        .finally(() => {
          this.loading = false
        })
    },
    // 获取支付结果
    getPaymentResult () {
      return this.getOrderDetail(this.payInfo).then(res => {
        if (res.success) {
          const status = res.data.status
          switch (+this.payInfo.type) {
            case 0: // 直接大定场景，重新查询订单状态：定金未支付———>定金已付待确认
              return status === '20'
            case 1: // 直接下DR场景，重新查询订单状态：意向金未支付——>意向金已支付
              return status === '02'
            case 2: // DR转大定场景，同转大定时判断是否有差价的方法，无差价则认为支付完成(「车型大定金额」-「已收金额」的差额，如果差额小于等于0，即已经没有差价)
              return +res.data.depositAmount - +res.data.receivedAmount <= 0
          }
        }
      })
    },
    // 支付成功，进入订单详情
    clickInfo () {
      if (this.pageParam.pageClose) {
        this.$router.go(-1)
      } else {
        this.$router.push({
          path: '/orderDetail',
          query: {
            orderNum: this.payInfo.orderNo,
            source: 'userInfo'
          }
        })
      }
    }
  },
  mounted () {
    this.nonPayment = true
    const pageParam = this.$route.query || {}
    const { type, orderNo, amount, subject, channel } = pageParam
    if (!orderNo || !amount || !subject) {
      return Dialog.alert({
        title: '提示',
        message: 'Data retrieval error!'
      }).then(() => {
        this.$router.go(-1)
      })
    }
    this.payInfo = {
      type,
      orderNo,
      amount,
      subject,
      channel: channel || this.$storage.get('channel')
    }
    this.pageParam = pageParam
    this.labelChange(this.lables.list[0])
  }
}
</script>
<style lang="less" scoped>
/deep/ #container {
  display: flex;
  flex-direction: column;

  .payment {
    flex: 1;
  }

  .btns {
    margin-top: 80px;
    text-align: center;
  }
}
</style>